.plans-padding {
	padding: 4rem 8vw;
}

.inactive {
  opacity: 0.5;
}

.line-through {
	text-decoration: line-through;
}

.bold {
	font-weight: 900;
}

.comparison-table {
	justify-content: center;
	gap: 20px;
}

.comparison-title {
	max-width: 1000px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 1.5rem;
	margin-top: 5rem;
}

.baseline {align-items: baseline;}

.plans {
	display: flex;
}

.plan-section {
	margin-top: 1.2rem;
}


.plan-section p {
	margin-bottom: 0.5rem;
}

.plan {
	border: 1px solid #E1E1EA;
	border-radius: 0px;
	padding: 2rem;
	padding-bottom: 1rem;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	margin-left: -1px;
  transition: 0.3s ease-out;
  position: relative;
}

.addons {
	border-radius: 5px !important;
	background-color: #FFFFFF !important;
	flex: 1;
	max-width: 24%;
}

.recommended {
	background-color: #F6F9FB;
}

.plan:first-child {
	border-radius: 5px 0px 0px 5px;
}

.plan:last-child {
	border-radius: 0px 5px 5px 0px;
}

.plan:nth-child(2) {
	border-radius: 0px 5px 5px 0px;
	margin-left: -1px;
}


.plan:nth-child(3) {
	border-radius: 5px;
	margin-left: 24px;
} 

/* .plan:hover {
  border: 1px solid #6487A3;
} */

.highlighted {
	color: #588DB7 !important;
}

.grey {
	color: #737F93 !important;
}

.plan-button {
	padding: 1rem 0;
	position: sticky;
    bottom: 0;
}

.plan a {
	display: block;
}

.plan h4 {
	color: #4A5363;
	margin-right: 0.5rem;
	margin-top: 0rem;
}

.plan h3 {
	color: #30333C;
	margin-top: 0.5rem;
}

.no-margin-top {
	margin-top: 0;
}

.discount {
	background-color: #edf0f3;
	padding: 0px 10px;
	border-radius: 50px;
	margin-left: 8px;
	height: max-content;
	font-weight: 500;
}

.discount span {
	font-size: 0.9rem;
	color: #588db7;
}

.discount.active {
	background-color: #FFFFFF;
	height: max-content;
}

.discount.active span {
	color: #588DB7;
}

.plan-title {
	margin-bottom: 10px;
}

.plan-description {
	margin-bottom: 2rem;
	font-size: 1.1rem;
}

.plan-features {
	list-style: none;
	/* margin: 0.5rem 0 1rem 0; */
	padding: 0;
	text-align: left;
}

.plan-features li {
	font-size: 1.1rem;
	margin-bottom: 1rem;
	position: relative;
	padding-left: 30px;
}

.addons-features {
	list-style: disc;
	margin: 0.5rem 0 1rem 0;
	padding-left: 20px;
	text-align: left;
}

.addons-features li {
	font-size: 1.1rem;
	margin-bottom: 0.5rem;
	position: relative;
	padding-left: 8px;
}

.addons-features li::marker {
	color: #A2ACB9;
}

.checkmark-icon {
	position: absolute;
	left: 0;
	top: 0;
	padding-top: 2px;
	width: 20px;
	height: 20px;
}

.plan-recommended {
  position: absolute;
  transform: translateY(-0.5rem);
  color: #97826B;
}

.plan-recommended-border {
  border: 1px solid #97826B;
}

.price-small {
	color: #737F93;
	padding-left: 8px;
}

.tiny {
	font-size: 1rem;
}

.explainer {
	margin-top: -1rem;
	font-size: 0.9rem;
	font-style: italic;
}

.toggle-container {
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
  }
  
  .toggle-button {
	border: 1px solid #E1E1EA;
	background-color: #FFFFFF;
	cursor: pointer;
	transition: background-color 0.3s ease;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 1rem;
	color: #30333C;
	font-family: 'MyFont', sans-serif;
  }
  
  .toggle-button:not(:last-child) {
	border-right: none;
	border-radius: 50px 0 0 50px;
	padding: 0.5rem 1rem 0.5rem 1.1rem;
  }

  .toggle-button:last-child {
	border-radius: 0 50px 50px 0;
	padding: 0.5rem 1.1rem 0.5rem 1rem;
  }
  
  .toggle-button:hover {
	background-color: #edeef0;
  }

  .toggle-button.active {
	background-color: #588DB7;
	color: white;
  }

.divider {
	height: 1px;
	background-color: #E1E1EA;
	margin-top: 32px;
	margin-bottom: 24px;
}

@media (max-width: 1050px) {

	.plans-padding {
		padding: 4rem 4vw;
	}

	.plans {
		display: flex;
		flex-direction: column;
	}

	.plan {
		padding: 1.6rem;
		margin-left: 0 !important;
		margin-top: 24px !important;
		border-radius: 5px !important;
	}

	.plan span {
		font-size: 1rem;
	}

	.plan-features li,
	.addons-features li {
		font-size: 1rem;
	}

	.plan:first-of-type {
		margin-top: 0px !important;
	}

	.addons {
		border-radius: 5px !important;
		background-color: #FFFFFF !important;
		flex: 1;
		max-width: unset;
	}
	
	/* .plan:nth-child(1) {
		background-color: #F6F9FB;
		border-radius: 5px 5px 0px 0px;
	}
	
	.plan:nth-child(2) {
		border-radius: 0px 0px 5px 5px;
		margin-top: -1px;
		margin-left: 0;
	}
	
	.plan:nth-child(3) {
		border-radius: 0px 5px 5px 0px;
		margin-top: 24px;
		margin-left: 0;
	} */

}