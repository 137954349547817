@font-face {
  font-family: 'MyFont';
  src: url('../fonts/Urbanist-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyFont';
  src: url('../fonts/Urbanist-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'MyFont', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.app {
  overflow: hidden;
}

.max-width {
  max-width: 900px;
}

/* Fonts */

h1 {
  font-size: 3.8rem;
  font-weight: bold;
  margin: 0rem 0rem 1.5rem 0rem;
  color: #30333C;
}

h2 {
  font-size: 3rem;
  margin: 1rem 0rem;
  color: #30333C;
}

h3 {
  font-size: 2rem;
  margin: 2rem 0rem 1rem 0rem;
  color: #4A5363;
}

h4 {
  font-size: 1.3rem;
  margin: 2rem 0rem 1rem 0rem;
  color: #69758b;
}

p, span {
  font-size: 1.2rem;
  margin: 1rem 0rem;
  color: #30333C;
  line-height: 1.8rem;
}

a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #30333C;
  transition: 0.3s ease-out;
}

a:active {
  transform: scale(0.97);
}

.bold {
  font-weight: 900;
}

.italic {
  font-style: italic;
  color: #3B5162;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
    margin: 1rem 0rem;
    color: #30333C;
  }
  
  h2 {
    font-size: 1.7rem;
    margin: 1rem 0rem;
    color: #30333C;
  }
  
  h3 {
    font-size: 1.5rem;
    margin: 1rem 0rem;
    color: #30333C;
  }
  
  h4 {
    font-size: 1.2rem;
    margin: 1rem 0rem;
    color: #30333C;
  }

  p {
    font-size: 1rem;
  }

}


/* Various */

.bg-grey {
  background-color: #F2F3F4;
}

.main-padding {
  padding: 4rem 8vw;
}

.margin-right {
  margin-right: 1.2rem;
}

.arrow::after {
  content: '';
  display: inline-block;
  background-image: url('../assets/arrow.svg');
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-left: 0.5rem;
  transition: 0.3s ease-out;
}

.arrow:hover::after {
  margin-left: 0.8rem;
}

.sub-label {
  color: #7D889B;
  font-weight: 0.8rem;
  line-height: 1.4rem;
}

.fill {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.inline-block {
  display: inline-block;
}

.Section {
  min-height: 100vh;
  padding: 4rem 8vw;
  align-items: center;
  justify-content: flex-start;
}

.Section.small {
  min-height: 75vh;
  padding: 4rem 8vw;
  align-items: center;
  justify-content: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.main-padding {
  padding: 4rem 8vw;
}

.max-width-text {
  max-width: 60rem;
}

.no-padding {
  padding: 0;
}

.app-logo {
  height: 2.6rem;
  pointer-events: none;
  margin-right: 4rem;
}

@media (max-width: 768px) {
  .Section {
    min-height: 80vh;
    padding: 4rem 9vw;
    align-items: center;
    justify-content: flex-start;
  }

  .main-padding {
    padding: 4rem 1rem;
  }

  .app-logo {
    height: 2.6rem;
    pointer-events: none;
    margin-right: 0rem;
  }
}

/* Button Primary */

.button-primary {
  background-color: #97826B;
  color: #ffffff;
  border-radius: 0.3rem;
  padding: 0.8rem 1.4rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  transition: 0.2s ease-out;
  text-align: center;
}

.button-primary:hover {
  filter: brightness(1.1);
}

.button-primary:active {
  background-color: #696056;
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .button-primary {
    display: block;
    width: auto !important;
    margin-right: 0 !important;
  }
}

/* Button Secondary */

.button-secondary {
  background-color: #ffffff;
  color: #97826B;
  border-radius: 0.3rem;
  border: 1px solid #E1E1EA;
  padding: 0.8rem 1.4rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  transition: 0.2s ease-out;
  text-align: center;
}

@media (max-width: 768px) {
  .button-secondary {
    display: block;
    width: auto !important;
    margin-right: 0 !important;
  }
}

.button-secondary:hover {
  background-color: #97826B;
  border-color: #97826B;
  color: #ffffff;
  filter: brightness(1.1);
}

.button-secondary:active {
  background-color: #696056;
  border-color: #696056;
  transform: scale(0.98);
}

.small-padding {
  padding: 0.6rem 1.2rem;
}

.large {
  padding: 1rem 1.8rem;
  font-size: 1.2rem;
}


/* Header */

.app-header {
  background-color: #F2F3F4;
  min-height: 94vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-top: 1.8rem;
}

.app-header-content {
  padding: 4rem 7vw 4rem 8vw;
}

.app-header-content p {
  margin-bottom: 2rem;
}

.app-header-buttons {
  display: flex;
  flex-direction: row;
}

.app-header-buttons a {
  width: fit-content;
  margin-top: 0.8rem;
}

.app-header-buttons span {
  color: #4A5363;
  font-style: italic;
}

.left-half {
  align-self: center;
  width: 54vw;
}

.right-half {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 94vh;
  width: 46vw;
  background-size: cover;
  padding-right: 11vw;
}

.right-half img {
  width: 100%;
  transform: scale(1.15);
}

.see-more {
  background-color: white;
  width: 4rem;
  height: 2.5rem;
  position: absolute;
  bottom: 1.1rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 30px 30px 0 0;
  padding-bottom: 1rem;
}

.see-more img {
  animation: bounce 3s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .app-header {
    background-color: #F2F3F4;
    min-height: 94vh;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    padding-top: 7rem;
    overflow: unset;
  }
  
  .app-header-content {
    padding: 0rem 9vw;
  }
  
  .app-header-content p {
    margin-bottom: 1.1rem;
  }

  .app-header-buttons {
    flex-direction: column;
  }

  .app-header-buttons a:last-child {
    margin-top: 0.8rem;
  }
  
  .left-half {
    align-self: center;
    width: 100vw;
  }
  
  .right-half {
    height: 26vh;
    width: auto;
    padding-right: 0rem;
    text-align: center;
  }

  .right-half img {
    padding: 0 9vw;
    width: 80%;
    transform: unset;
  }
  
  .see-more {
    display: none;
  }
  
  .see-more img {
    animation: bounce 3s ease-in-out infinite;
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
}

/* animations */

.fade-in-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}


/* Features */

/* .feature-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.feature-image {
  background-image: url('https://images.pexels.com/photos/5077049/pexels-photo-5077049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  background-size: cover;
  min-height: 30rem;
  flex: 1;
  border-radius: 0.5rem;
}

.feature-item-content-left {
  flex: 1;
  padding: 0 4rem 0 0;
}

.feature-item-content-right {
  flex: 1;
  padding: 0 0 0 4rem;
} */


@media (max-width: 768px) {
  .feature-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .image-right {
    flex-direction: column-reverse;
  }

  .feature-image {
    background-size: cover;
    min-height: 15rem;
    width: 100%;
    flex: 1;
    border-radius: 0.5rem;
  }

  .feature-item-content-left {
    flex: 1;
    padding: 1rem 0 0 0;
  }
  
  .feature-item-content-right {
    flex: 1;
    padding: 1rem 0 0 0;
  }
}

